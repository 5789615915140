import React from "react";

export const CustomDropdown = ({
  list,
  placeholder,
  onDropdownChange,
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({
    id: null,
    value: defaultValue,
  });
  return (
    <div className="relative sm:w-full md:w-60 ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        class={` font-nunito bg-holl-input text-start sm:h-12 w-full flex  justify-between  ${
          isOpen == true ? "rounded-t-3xl rounded-b-none" : "rounded-sm"
        }   px-4 py-1 text-center text-holl-input inline-flex items-center `}
        type="button"
      >
        {selected.value}
        <svg
          class="w-10 h-10 ml-2 flex-shrink-0"
          fill="none"
          stroke="white"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <div
        id="dropdown"
        class={`z-10 ${
          isOpen == false ? "hidden" : "rounded-t-none"
        } bg-gray-300  divide-y divide-gray-100 rounded shadow   dark:bg-gray-700 absolute left-0 top-full w-full h-40  overflow-y-auto `}
      >
        <ul
          class="py-1 text-sm w-full text-gray-700 dark:text-gray-200 "
          aria-labelledby="dropdownDefault"
        >
          {list.map((key, index) => {
            return (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setSelected(key);
                    onDropdownChange(key);
                    setIsOpen(false);
                  }}
                  class="w-full  block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {key.value}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
