import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { CustomDropdown } from "./dropdown/CustomDropdown";
import { Departments } from "../constants/Departments";
import { EdStatus } from "../constants/EdStatus";

const schema = Yup.object().shape({
  name: Yup.string("İsim").required("Zorunlu alan!"),
  surname: Yup.string("Soyisimi").required("Zorunlu alan!"),
  email: Yup.string("Email")
    .required("Zorunlu alan!")
    .matches(
      /^[a-zA-Z_\-.0-9]+@[a-z]+\.edu\.[a-z]{2,3}/,
      "edu.tr uzantılı olmalı!"
    ),
  phone: Yup.string("Telefon").required("Zorunlu alan!"),
});

export const ApplicationForm = () => {
  return (
    <div className="flex z-20 font-nunito pb-3 flex-col items-center bg-form-box drop-shadow-form-box sm:w-8/12 md:p-12   rounded-lg  pt-8">
      <p className="font-nunito font-bold md:text-3xl text-holl-header">
        Application Form
      </p>
      <Formik
        initialValues={{ email: "", name: "", surname: "", phone: "" }}
        validationSchema={schema}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form
            className="w-full space-y-4  flex sm:items-center justify-center flex-col px-4 mt-6"
            onSubmit={handleSubmit}
          >
            <Field
              type="string"
              className="sm:h-12 placeholder:text-hoodup-input text-hoodup-input p-4 bg-input sm:w-full md:w-60 rounded-sm outline-none "
              name="name"
              placeholder="İsmin"
              onChange={handleChange}
              values={values.name}
            />
            {touched.name && errors.name && (
              <p className="pl-3  font-montserrat text-rose-600  ">
                {errors.name}
              </p>
            )}
            <Field
              type="string"
              className="sm:h-12 placeholder:text-hoodup-input text-hoodup-input p-4 bg-input sm:w-full md:w-60 rounded-sm outline-none "
              name="surname"
              placeholder="Soyismin"
              onChange={handleChange}
              values={values.surname}
            />
            {touched.surname && errors.surname && (
              <p className="pl-3  font-montserrat text-rose-600  ">
                {errors.surname}
              </p>
            )}
            <CustomDropdown list={Departments} defaultValue="Bölümün" />
            <Field
              type="email"
              className="sm:h-12 placeholder:text-hoodup-input text-hoodup-input p-4 bg-input sm:w-full md:w-60 rounded-sm outline-none "
              name="email"
              placeholder="edu.tr mail adresin"
              onChange={handleChange}
              values={values.email}
            />
            {touched.email && errors.email && (
              <p className="pl-3  font-montserrat text-rose-600  ">
                {errors.email}
              </p>
            )}
            <Field
              type="number"
              className="sm:h-12 placeholder:text-hoodup-input text-hoodup-input p-4 bg-input sm:w-full md:w-60 rounded-sm outline-none "
              name="phone"
              placeholder="Telefon Numaran"
              onChange={handleChange}
              values={values.phone}
            />
            {touched.phone && errors.phone && (
              <p className="pl-3  font-montserrat text-rose-600  ">
                {errors.phone}
              </p>
            )}
            <CustomDropdown
              list={EdStatus}
              defaultValue="Master/Doktora/Lisans"
            />
            <div className="sm:w-full pl-2 pr-2  items-center justify-between  flex flex-row md:w-60  ">
              <p className="text-hoodup-input text-sm mt-3">
                Are you an{" "}
                <a className="text-hoodup-yellow">exchange student?</a>
              </p>
              <div class="flex items-center mt-3">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-hoodup-yellow bg-input rounded border-gray-300 focus:ring-[#CBE23A] dark:focus:ring-[#CBE23A] dark:ring-offset-[#CBE23A] focus:ring-2 dark:bg-[#CBE23A] border-[#CBE23A]"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
