export const EdStatus = [
  {
    id: 1,
    value: "Master",
  },
  {
    id: 2,
    value: "Doktora",
  },
  {
    id: 3,
    value: "Lisans",
  },
  {
    id: 4,
    value: "Mezun",
  },
  { id: 5, value: "Diğer" },
];
