import { Agreements } from "../../constants/Agreements";
export const AgreementModal = ({ agreementIndex, onModalClose }) => {
  return (
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      class="  overflow-x-hidden  fixed sm:top-0  right-0 sm:left-0 md:left-80 overflow-scroll z-50 w-full md:inset-0 h-modal sm:h-full   md:h-full justify-center items-center"
    >
      <div class="relative p-4  w-full max-w-2xl h-full md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              {Agreements[agreementIndex].header}
            </h3>
            <button
              onClick={() => {
                onModalClose();
              }}
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>

          <div class="p-6 space-y-6 overflow-y-auto">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {Agreements[agreementIndex].text}
            </p>
          </div>

          <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
            <button
              onClick={() => {
                onModalClose();
              }}
              data-modal-toggle="defaultModal"
              type="button"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Okudum
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
