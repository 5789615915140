import ag1 from "./ag1.txt";
import ag2 from "./ag2.txt";
import ag3 from "./ag3.txt";

const zımba = fetch(ag1)
  .then((r) => r.text())
  .then((text) => (Agreements[0].text = text));

const zımba1 = fetch(ag2)
  .then((r) => r.text())
  .then((text) => {
    Agreements[1].text = text;
  });

const zımba2 = fetch(ag3)
  .then((r) => r.text())
  .then((text) => {
    Agreements[2].text = text;
  });

export const Agreements = [
  {
    header: "Üyelik Sözleşmesi",
    text: `sss`,
  },
  {
    header: "Aydınlatma Metni",
    text: "Aydınlatma Metni Metni",
  },
  {
    header: "Kullanım Koşulları",
    text: "Kullanım Koşulları",
  },
];
