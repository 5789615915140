import React from "react";

export const Opportunities = [
  {
    id: 1,
    icon: require("../../assets/opps/1.png"),
    title: "2 Ay  Premium Bedava",
  },
  {
    id: 2,
    icon: require("../../assets/opps/2.png"),
    title: "İletişim Ağımızdasın!",
  },
  {
    id: 3,
    icon: require("../../assets/opps/3.png"),
    title: "Premium Çekilişindesin!",
  },
];

export const Welcome = ({ name }) => {
  return (
    <div className="w-full h-full  flex flex-col justify-center relative  ">
      <div className="flex z-10 sm:flex-col md:flex-row-reverse font-nunito self-center md:justify-between sm:w-11/12 rounded-xl md:w-8/12 sm:h-2/5  md:h-5/12 mt-12  items-center justify-center relative bg-white drop-shadow-form-box ">
        <img
          src={require("../../assets/icons/1.png")}
          className=" -mt-16 md:w-72 md:self-end   md:justify-self-end  "
        />
        <div className="md:pl-40 md:-mt-20 flex items-center flex-col">
          <p className="text-holl-header mt-2 font-bold text-3xl md:text-4xl justify-self-start">
            Hoş Geldin!
          </p>
          <p className="text-holl-input mt-2 font-bold text-2xl justify-self-start">
            {name}
          </p>
        </div>
      </div>
      <div className="z-0 font-nunito self-center justify-center p-8 pt-5 -mt-3 bg-white drop-shadow-form-box rounded-xl md:w-1/2 sm:w-9/12">
        <p className="text-holl-input text-center sm:text-sm">
          Seni Holl'da görmekten çok mutluyuz! Biliyorsun ki uygulamanın
          çıkmasına çok az kaldı. Bu heyecanlı son düzlüğü de atlattığımız zaman
          ilk sana haber vereceğimizi de bilmeni isteriz :){" "}
        </p>
      </div>
      <div className="font-nunito flex flex-row justify-between space-x-2 self-center mt-6 md:mt-20 md:w-7/12">
        {Opportunities.map((key, index) => {
          return (
            <div className="flex flex-col items-center justify-center">
              <img className="" src={key.icon} />
              <p className="text-hoodup-input text-xs mt-2">{key.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
