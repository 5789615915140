export const Departments = [
  {
    id: 1,
    value: "Kimya",
  },
  {
    id: 2,
    value: "Tarih",
  },
  {
    id: 3,
    value: "Dilbilimi",
  },
  {
    id: 4,
    value: "Matematik",
  },
  {
    id: 5,
    value: "Moleküler Biyoloji ve Genetik",
  },
  {
    id: 6,
    value: "Felsefe",
  },
  {
    id: 7,
    value: "Sosyoloji",
  },
  {
    id: 8,
    value: "Çeviribilim",
  },
  {
    id: 9,
    value: "Türk Dili ve Edebiyatı",
  },
  {
    id: 10,
    value: "Batı Dilleri ",
  },
  {
    id: 11,
    value: "Ekonomi",
  },
  {
    id: 12,
    value: "İşletme",
  },
  {
    id: 13,
    value: "Politika",
  },
  {
    id: 14,
    value: "Bilgisayar Öğretmenliği",
  },
  {
    id: 15,
    value: "İngilizce Öğretmenliği",
  },
  {
    id: 16,
    value: "İlkokul Öğretmenliği",
  },
  {
    id: 17,
    value: "Matematik Öğretmenliği",
  },
  {
    id: 18,
    value: "Kimya Mühendisliği",
  },
  {
    id: 19,
    value: "İnşaat Mühendisliği",
  },
  {
    id: 20,
    value: "Bilgisayar Mühendisliği",
  },
  {
    id: 21,
    value: "Elektrik Elektronik Mühendisliği ",
  },
  {
    id: 22,
    value: "Endüstri Mühendisliği ",
  },
  {
    id: 23,
    value: "Makine Mühendisliği ",
  },
  {
    id: 24,
    value: "Uluslarası Ticaret ",
  },
  {
    id: 25,
    value: "Yönetim Bilişim Sistemleri ",
  },
  {
    id: 26,
    value: "Turizm ",
  },
  {
    id: 27,
    value: "Arkeoloji ve Sanat Tarihi ",
  },
  {
    id: 28,
    value: "Medya ve Görsel Sanatlar ",
  },
  {
    id: 29,
    value: "Arkeoloji ve Sanat Tarihi ",
  },
  {
    id: 30,
    value: "Psikoloji ",
  },
  {
    id: 31,
    value: "Hukuk ",
  },
  {
    id: 32,
    value: "Fizik ",
  },

  {
    id: 33,
    value: "Tıp ",
  },
  {
    id: 34,
    value: "Elektronik ve Haberleşme Mühendisliği ",
  },
  {
    id: 35,
    value: "İç Mimarlık ",
  },
  {
    id: 36,
    value: "Mimarlık ",
  },
  {
    id: 37,
    value: "Konservatuar ",
  },
  {
    id: 38,
    value: "Diğer",
  },
];
