import React from "react";
import { ReactComponent as Logo } from "../../assets/logos/logo.svg";
import { Opportunities } from "../../constants/Opportunities";
import { collection, addDoc } from "firebase/firestore";
import { ApplicationForm } from "../../components/ApplicationForm";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { CustomDropdown } from "../../components/dropdown/CustomDropdown";
import { Departments } from "../../constants/Departments";
import { EdStatus } from "../../constants/EdStatus";
import { Welcome } from "../welcome/Welcome";
import { firestore } from "../../firebase";
import { AgreementModal } from "../../components/modals/AgreementModal";

const schema = Yup.object().shape({
  name: Yup.string("İsim").required("Zorunlu alan!"),
  surname: Yup.string("Soyisimi").required("Zorunlu alan!"),
  /*email: Yup.string("Email")
    .required("Zorunlu alan!")
    .matches(
      /^[a-zA-Z_\-.0-9]+@[a-z]+\.edu\.[a-z]{2,3}/,
      "edu.tr uzantılı olmalı!"
    ),*/

  email: Yup.string("Email")
    .email("Geçerli bir email adresi giriniz.")
    .required("Zorunlu alan!"),
  phone: Yup.string("Telefon").required("Zorunlu alan!"),
});

export const FormScreen = () => {
  const [selectedDepartment, setSelectedDepartment] = React.useState({
    id: null,
    value: null,
  });
  const [selectedEdStatus, setSelectedEdStatus] = React.useState({
    id: null,
    value: null,
  });
  const [isChecked, setIsChecked] = React.useState(false);
  const [isSMSChecked, setIsSMSChecked] = React.useState(false);
  const [isMailChecked, setIsMailChecked] = React.useState(false);
  const [isAggreementsChecked, setIsAgreementsChecked] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [agreementIndex, setAgreementIndex] = React.useState(0);

  const [isErrorForAgreements, setIsErrorForAgreeements] =
    React.useState(false);
  const [isErrorForDep, setIsErrorForDep] = React.useState(false);
  const [isErrorForEd, setIsErrorForEd] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  return (
    <Formik
      className="w-full h-full"
      initialValues={{ email: "", name: "", surname: "", phone: "" }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        if (
          selectedDepartment.id == null ||
          selectedEdStatus.id == null ||
          isAggreementsChecked == false
        ) {
          if (isAggreementsChecked == false) {
            setIsErrorForAgreeements(true);
          } else {
            setIsErrorForAgreeements(false);
          }
          if (selectedDepartment.id == null) {
            setIsErrorForDep(true);
          } else {
            setIsErrorForDep(false);
          }
          if (selectedEdStatus.id == null) {
            setIsErrorForEd(true);
          } else {
            setIsErrorForEd(false);
          }
        } else {
          console.log(values, selectedDepartment, selectedEdStatus, isChecked);
          setIsLoading(true);
          //

          const docRef = await addDoc(collection(firestore, "waitlist"), {
            name: values.name,
            surname: values.surname,
            department: selectedDepartment,
            email: values.email,
            phone: values.phone,
            edStatus: selectedEdStatus,
            isExchange: isChecked,
            isSMSChecked: isSMSChecked,
            isMailChecked: isMailChecked,
          });

          setIsLoading(false);
          setIndex(1);
        }
      }}
    >
      {({ handleChange, values, touched, errors, handleSubmit }) => (
        <div className="flex flex-col justify-start items-center pt-12 w-full h-full bg-input relative rounded-lg  ">
          {isModalOpen ? (
            <AgreementModal
              onModalClose={() => {
                setIsModalOpen(false);
              }}
              agreementIndex={agreementIndex}
            />
          ) : (
            <></>
          )}
          <div className="w-20 h-20 flex-shrink-0">
            <Logo />
          </div>
          {index == 0 ? (
            <Form
              onSubmit={handleSubmit}
              className={
                isModalOpen
                  ? "opacity-25 w-full h-full mt-4"
                  : "w-full h-full mt-4"
              }
            >
              <div className="sm:mt-16 md:mt-40  md:px-44 md:justify-between w-full flex relative sm:flex-col md:flex-row pr-6 items-start pl-4  ">
                <div className="md:-mt-16 ">
                  <p className="text-white font-kanit text-5xl leading-10 ">
                    LET'S <br /> START THE <br />
                    <a className="text-hoodup-yellow">SOCIAL FUSION</a>
                  </p>
                </div>
                <img
                  className="mt-12 self-center"
                  src={require("../../assets/images/three.png")}
                />
                <div className="self-end">
                  <p className="text-white font-kanit text-5xl leading-10 mt-12 text-end">
                    BE ONE <br /> OF THE <br />{" "}
                    <a className="text-hoodup-yellow ">PIONEERS</a>
                  </p>
                </div>
              </div>
              <div className="mt-32 flex relative sm:flex-col md:flex-row-reverse md:pr-20 pr-6 pl-4 items-start">
                <div className="flex flex-col md:w-7/12 md:p-16">
                  <p className="font-nunito font-bold text-white text-2xl">
                    Hop, çağrın var!
                  </p>
                  <p className="font-nunito font-bold text-sm text-white mt-6 ">
                    <a className="text-hoodup-yellow">Sosyal hayatınla</a>{" "}
                    ilgili her şeyi içeren bu kocaman dünya cebinde, ama cebine
                    sığamayacak kadar da{" "}
                    <a className="text-hoodup-yellow">gerçek!</a>
                  </p>
                  <p className="text-xs font-nunito text-white mt-4">
                    <a className="text-hoodup-yellow">Holl'da</a> senin gibi
                    kullanıcılara buluşma çağrısı gönderebilirsin, istersen de
                    sadece arkadaşlarına veya öğrencilere. Herkese tek tek mesaj
                    atmaya son ;)
                  </p>
                  <p className="text-xs font-nunito text-white mt-2">
                    İstersen Kadıköy'deki iki saatlik vaktini sohbet muhabbetle
                    değerlendir, istersen tüm gece partile, istersen de 25 saat
                    boyunca midterme çalış.{" "}
                  </p>
                </div>
                <div className="relative sm:hidden  md:flex w-full  h-96 mt-16 ">
                  <img
                    src={require("../../assets/images/left-hand.png")}
                    className="absolute md:w-56 top-0 left-0 "
                  />
                  <img
                    className="absolute sm:bottom-0 sm:right-0"
                    src={require("../../assets/images/right-hand.png")}
                  />
                </div>
              </div>
              <div className="relative sm:flex md:hidden h-96 mt-16 ">
                <img
                  src={require("../../assets/images/left-hand.png")}
                  className="absolute top-0 left-0 "
                />
                <img
                  className="absolute sm:bottom-0  sm:right-0 "
                  src={require("../../assets/images/right-hand.png")}
                />
              </div>
              <div className="w-full flex flex-row pl-2 sm:mt-20 md:items-center justify-center ">
                <div className="flex z-20 font-nunito pb-3 flex-col items-center bg-white drop-shadow-form-box sm:w-9/12 md:w-6/12 md:-mt-8 md:p-12 mt-8 pt-8   rounded-lg  ">
                  <p className="font-nunito font-bold md:text-3xl text-holl-header">
                    Application Form
                  </p>

                  <div
                    className="w-full space-y-6  flex sm:items-center justify-center flex-col px-4 mt-6"
                    onSubmit={handleSubmit}
                  >
                    <div className="items-center justify-center flex sm:w-full relative">
                      <Field
                        type="string"
                        className="sm:h-12 placeholder:text-holl-input text-holl-input p-4 bg-holl-input sm:w-full md:w-60 rounded-sm outline-none "
                        name="name"
                        placeholder="İsmin"
                        onChange={handleChange}
                        values={values.name}
                      />
                      {touched.name && errors.name && (
                        <p className="pl-3 mt-16 absolute font-montserrat text-rose-600  ">
                          {errors.name}
                        </p>
                      )}
                    </div>
                    <div className="items-center flex justify-center relative w-full">
                      <Field
                        type="string"
                        className="sm:h-12 placeholder:text-holl-input text-holl-input p-4 bg-holl-input sm:w-full md:w-60 rounded-sm outline-none "
                        name="surname"
                        placeholder="Soyismin"
                        onChange={handleChange}
                        values={values.surname}
                      />
                      {touched.surname && errors.surname && (
                        <p className="pl-3 mt-16 absolute font-montserrat text-rose-600  ">
                          {errors.surname}
                        </p>
                      )}
                    </div>
                    <div className="items-center flex justify-center relative w-full">
                      <CustomDropdown
                        list={Departments}
                        onDropdownChange={(value) => {
                          setSelectedDepartment(value);
                          console.log(selectedDepartment);
                        }}
                        defaultValue="Bölümün"
                      />
                      {isErrorForDep ? (
                        <p className="absolute mt-16 text-rose-800">
                          Lütfen seçiniz!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="justify-center flex items-center relative w-full">
                      <Field
                        type="email"
                        className="sm:h-12 placeholder:text-holl-input text-holl-input p-4 bg-holl-input sm:w-full md:w-60 rounded-sm outline-none "
                        name="email"
                        placeholder="Mail adresin"
                        onChange={handleChange}
                        values={values.email}
                      />
                      {touched.email && errors.email && (
                        <p className="pl-3 mt-16 absolute font-montserrat text-rose-600  ">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="flex justify-center items-center relative w-full">
                      <Field
                        type="number"
                        className="sm:h-12 placeholder:text-holl-input text-holl-input p-4 bg-holl-input sm:w-full md:w-60 rounded-sm outline-none "
                        name="phone"
                        placeholder="Telefon Numaran"
                        onChange={handleChange}
                        values={values.phone}
                      />
                      {touched.phone && errors.phone && (
                        <p className="pl-3 absolute mt-16 font-montserrat text-rose-600  ">
                          {errors.phone}
                        </p>
                      )}
                    </div>
                    <div className="w-full flex justify-center items-center self-center relative">
                      <CustomDropdown
                        list={EdStatus}
                        defaultValue="Master/Doktora/Lisans"
                        onDropdownChange={(value) => {
                          setSelectedEdStatus(value);
                        }}
                      />
                      {isErrorForEd ? (
                        <p className="absolute mt-16 text-rose-800">
                          Lütfen seçiniz!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="w-full flex flex-col items-center space-y-2">
                      <div className="sm:w-full pl-2 pr-2  items-center justify-between  flex flex-row md:w-60  ">
                        <p className="text-holl-input text-sm mt-3">
                          Are you an{" "}
                          <a className="text-holl-red">exchange student?</a>
                        </p>
                        <div class="flex items-center mt-3">
                          <input
                            value=""
                            onChange={(v) => {
                              setIsChecked(v.target.checked);
                            }}
                            id="checked-checkbox"
                            type="checkbox"
                            class="w-4 h-4 text-hoodup-yellow bg-input rounded border-gray-300 focus:ring-[#CBE23A] dark:focus:ring-[#CBE23A] dark:ring-offset-[#CBE23A] focus:ring-2 dark:bg-[#CBE23A] border-[#CBE23A]"
                          />
                        </div>
                      </div>
                      <div className="sm:w-full pl-2 pr-2   items-center justify-between  flex flex-row md:w-60  ">
                        <p className="text-holl-input text-sm mt-3">
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                              setAgreementIndex(0);
                            }}
                            type="button"
                            className="text-holl-input"
                          >
                            Üyelik sözleşmesini
                          </button>
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                              setAgreementIndex(1);
                            }}
                            type="button"
                          >
                            Aydınlatma metnini
                          </button>
                          <button
                            onClick={() => {
                              setIsModalOpen(true);
                              setAgreementIndex(2);
                            }}
                            type="button"
                          >
                            Kullanım koşullarını
                          </button>
                          <p className="text-holl-red">
                            okudum ve kabul ediyorum.
                          </p>
                        </p>
                        <div class="flex items-center mt-3">
                          <input
                            value=""
                            onChange={(v) => {
                              setIsAgreementsChecked(v.target.checked);
                              if (v.target.checked == true) {
                                setIsErrorForAgreeements(false);
                              }
                            }}
                            id="checked-checkbox"
                            type="checkbox"
                            class="w-4 h-4 text-hoodup-yellow bg-input rounded border-gray-300 focus:ring-[#CBE23A] dark:focus:ring-[#CBE23A] dark:ring-offset-[#CBE23A] focus:ring-2 dark:bg-[#CBE23A] border-[#CBE23A]"
                          />
                        </div>
                      </div>
                      {isErrorForAgreements ? (
                        <div className="flex items-center">
                          <p className="text-rose-400">
                            Lütfen sözleşmeleri okuyup kabul ediniz!
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="sm:w-full pl-2 pr-2  items-center justify-between  flex flex-row md:w-60  ">
                        <p className="text-holl-red text-sm mt-3">
                          Kampanyalardan{" "}
                          <a className="text-holl-input">SMS aracılığıyla</a>{" "}
                          haberdar olmak istiyorum
                        </p>

                        <div class="flex items-center mt-3">
                          <input
                            value=""
                            onChange={(v) => {
                              setIsSMSChecked(v.target.checked);
                            }}
                            id="checked-checkbox"
                            type="checkbox"
                            class="w-4 h-4 text-holl-red bg-input rounded border-gray-300 focus:ring-[#CBE23A] dark:focus:ring-[#CBE23A] dark:ring-offset-[#CBE23A] focus:ring-2 dark:bg-[#CBE23A] border-[#CBE23A]"
                          />
                        </div>
                      </div>
                      <div className="sm:w-full pl-2 pr-2  items-center justify-between  flex flex-row md:w-60  ">
                        <p className="text-holl-input text-sm mt-3">
                          Kampanyalardan{" "}
                          <a className="text-holl-red">Mail aracılığıyla</a>{" "}
                          haberdar olmak istiyorum
                        </p>

                        <div class="flex items-center mt-3">
                          <input
                            value=""
                            onChange={(v) => {
                              setIsMailChecked(v.target.checked);
                            }}
                            id="checked-checkbox"
                            type="checkbox"
                            class="w-4 h-4 text-hoodup-yellow bg-input rounded border-gray-300 focus:ring-[#CBE23A] dark:focus:ring-[#CBE23A] dark:ring-offset-[#CBE23A] focus:ring-2 dark:bg-[#CBE23A] border-[#CBE23A]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:w-3/12 pl-4 pr-0 -ml-3 space-y-3 font-nunito rounded-xl h-33% flex flex-col self-center mt-6  items-center justify-center  h-96  bg-white  ">
                  {Opportunities.map((key, index) => {
                    return (
                      <div className="flex flex-col items-center space-y-1   ">
                        <img className="w-16 " src={key.icon} />
                        <p className="text-holl-input  text-center  text-xs">
                          {key.title}
                        </p>
                      </div>
                    );
                  })}
                  <div className=" sm:hidden md:flex pb-4 items-center w-full justify-center">
                    <button
                      type="submit"
                      className="sm:w-11/12 mt-6  bg-holl-button rounded-xl font-nunito bg-white h-10 text-white"
                    >
                      Yerimi ayırt
                    </button>
                  </div>
                </div>
              </div>

              {/*  <div className=" sm:w-3/12 sm:h-96 md:p-6 pt-8 pb-20 mb-20  space-y-6 self-center  flex flex-col   bg-form-box drop-shadow-form-box font-nunito">
          {Opportunities.map((key, index) => {
            return (
              <div className="flex flex-col items-center space-y-1   ">
                <img className="sm:w-16 md:w-24 " src={key.icon} />
                <p className="text-hoodup-input  text-center  text-xs">
                  {key.title}
                </p>
              </div>
            );
          })}
        </div>
        */}
              <div className="bg-input md:hidden flex pb-4 items-center justify-center">
                <button
                  type="submit"
                  className="sm:w-11/12 mt-6 flex items-center justify-center text-white  bg-holl-button rounded-xl font-nunito bg-white h-10"
                >
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 self-center"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "Yerimi ayırt"
                  )}
                </button>
              </div>
            </Form>
          ) : (
            <Welcome name={values.name} />
          )}
        </div>
      )}
    </Formik>
  );
};
