export const Opportunities = [
  {
    id: 1,
    icon: require("../assets/opps/1.png"),
    title: "2 Ay  Premium Hediye",
  },
  {
    id: 2,
    icon: require("../assets/opps/2.png"),
    title: "Holl sana ulaşsın",
  },
  {
    id: 1,
    icon: require("../assets/opps/3.png"),
    title: "Ömür Boyu   Premium Kazanma Şansı",
  },
];
