import { FormScreen } from "./screens/form";

function App() {
  return (
    <div className=" m-0  h-full w-full bg-hoodup-dark   ">
      <FormScreen />
    </div>
  );
}

export default App;
